import React from 'react'
import styles from './Header.module.css'
import RegisterLoginButtons from '../registerLoginButtons/RegisterLoginButtons'
import NavBar from './components/navBar/NavBar'
import CollapsibleControl from './components/collapsibleControl/CollapsibleControl'

const Header = ({
  className = '',
  orientation = 'landscape',
  isCollapsibleActive = false,
  onCollapsibleControlClick = () => { },
  ...restOfProps
}) => {

  return (
    <div className={`${styles.header} ${className}`} data-orientation={orientation} {...restOfProps}>

      <div className={styles.header_logoImageWrapper}>
        <img
          src={process.env.PUBLIC_URL + "/img/cjj_jalisco_no_background.png"}
          alt="Logo CJJ Colima"
          className={styles.header_logoImage}
        />
      </div>

      <div className={styles.header_navBar}>
        <NavBar className={styles.navBar} />
      </div>

      <div className={styles.registerLoginButtons}>
        <RegisterLoginButtons className={styles.registerLoginButtons} />
      </div>

      <div className={styles.header_collapsibleControl} onClick={onCollapsibleControlClick}>
        <CollapsibleControl isCollapsibleActive={isCollapsibleActive} />
      </div>
    </div>
  )
}

export default Header